import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";

import $ from "jquery";

import {
	Layout,
	Loader,
	HomeClients,
	HomeLineAnimation,
	ContactFormDark,
	HomeClientsDynamic,
} from "../components";

import locales from "../constants";

const IndexPage = ({ pageContext: { slug, langKey } }) => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			const section =
				document.getElementsByClassName("home-google-agency")[0];

			if (section) {
				setScroll(
					section.offsetTop - section.offsetHeight - window.scrollY
				);
			}
		});
	}, []);

	const onClientAnimation = () => {
		const plus = $(".line-animation__thumb");
		const isAnimated = plus.hasClass("running");
		if (isAnimated) {
			plus.addClass("spin");
			setTimeout(() => {
				plus.removeClass("spin");
			}, 500);
		}
	};

	const zoomBrief = () => {
		const briefCol = $(".col-custom--brief");
		const formCol = $(".col-custom--form");
		briefCol.addClass("on-hover");
		formCol.addClass("on-hover-child");
	};

	const unzoomBrief = () => {
		const briefCol = $(".col-custom--brief");
		const formCol = $(".col-custom--form");
		briefCol.removeClass("on-hover");
		formCol.removeClass("on-hover-child");
	};

	const zoomContact = () => {
		const briefCol = $(".col-custom--brief");
		const formCol = $(".col-custom--form");
		briefCol.addClass("on-hover-child");
		formCol.addClass("on-hover");
	};

	const unzoomContact = () => {
		const briefCol = $(".col-custom--brief");
		const formCol = $(".col-custom--form");
		briefCol.removeClass("on-hover-child");
		formCol.removeClass("on-hover");
	};

	const contactFormOpen = () => {
		const exitButton = $(".exit-form-button");
		const formCol = $(".col-custom--form");
		formCol.addClass("on-hover opened");
		exitButton.addClass("form-opened");
	};

	const closeFormByButton = () => {
		const exitButton = $(".exit-form-button");
		const formCol = $(".col-custom--form");
		exitButton.removeClass("form-opened");
		formCol.removeClass("on-hover opened");
	};

	return (
		<Layout
			seo={{
				title: "Agencja marketingu internetowego Kraków",
				description:
					"When to agencja marketingu internetowego. Jesteśmy specjalistami w dziedzinie kampanii reklamowych na platformach takich jak Google Ads, Facebook Ads, Linkedin Ads oraz Tiktok Ads. Kompleksowo zajmujemy się także prowadzeniem social media. Ekspercka wiedza, partnerska współpraca, imponujące wyniki. Wygrywaj z nami! #whenWEwin",
				href: slug,
				lang: langKey,
			}}
			scroll
		>
			{/* eslint-disable jsx-a11y/no-static-element-interactions */}
			{/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
			{/* <HomeLineAnimation /> */}
			<section className="home-top">
				<div className="home-top__wrapper">
					<div className="video-desktop">
						<LazyLoad once offset={1} placeholder={<Loader />}>
							{/* <video loop playsInline autoPlay muted className="video-desktop">
            <source
              src={require("../assets/video/video-hero.mp4")}
              type="video/mp4"
            />
          </video> */}
							<ReactPlayer
								url="https://when.zenx.pl/wp-content/themes/adream/assets/video/video-hero.mp4"
								playing={true}
								loop={true}
								muted={true}
								width="100%"
								height="100%"
								playsinline={true}
							/>
						</LazyLoad>
					</div>
					<div className="video-mobile">
						<LazyLoad once offset={1} placeholder={<Loader />}>
							<ReactPlayer
								url="https://when.zenx.pl/wp-content/themes/adream/assets/video/video575.mp4"
								playing={true}
								loop={true}
								muted={true}
								width="100%"
								height="100%"
								playsinline={true}
							/>
						</LazyLoad>
					</div>
				</div>
				<a href="#offers" className="scroll-container">
					<div className="mouse">
						<div className="scroller" />
					</div>
				</a>
			</section>

			<span className="anchor" id="offers"></span>
			<section className="home-offers">
				<div className="container-fluid">
					<div className="page-header">
						<h2>
							Usługi, które rozwiną
							<br />
							<span>Twój biznes</span>
						</h2>
						<p>
							Już od ponad 8 lat skutecznie i z wieloma sukcesami
							wspomagamy rozwój firm naszych klientów za pomocą
							nowoczesnych form marketingu internetowego w Polsce
							i za granicą. Zobacz, jak to robimy:
						</p>
					</div>
					<div className="home-offers__content">
						<div className="home-offers__content__col">
							<div className="home-offers__content__row home-offers__content__row--blue">
								<h3>Specjalizacje</h3>
								<ul>
									<li>
										<Link to="/marketing-dla-ecommerce">
											Marketing dla e-commerce
										</Link>
									</li>
									<li>
										<Link to="/marketing-dla-deweloperow">
											Marketing dla deweloperów
										</Link>
									</li>
									<li>
										<Link to="/marketing-produktow-inwestycyjnych">
											Marketing dla produktów
											inwestycyjnych
										</Link>
									</li>
									<li>
										<Link to="/marketing-dla-hoteli">
											Marketing dla hoteli
										</Link>
									</li>
								</ul>
							</div>
							<div className="home-offers__content__row home-offers__content__row--purple">
								<h3>Analityka</h3>
								<ul>
									<li>
										<Link to="/konfiguracja-i-wdrozenie-google-analytics-4">
											Konfiguracja i wdrożenie Google
											Analytics 4
										</Link>
									</li>
									<li>
										<Link to="/wdrozenie-analityki">
											Wdrożenie analityki
										</Link>
									</li>
									<li>
										<Link to="/analiza-strony-internetowej-przed-startem-kampanii-ppc/">
											Analiza strony internetowej przed
											startem kampanii PPC
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="home-offers__content__col">
							<div className="home-offers__content__row home-offers__content__row--purple">
								<h3>PPC</h3>
								<ul>
									<li>
										<Link to="/audyt-kampanii-google-facebook-ads">
											Audyt kampanii Google/Facebook Ads
										</Link>
									</li>
									<li>
										<Link to="/kampanie-googleads-search">
											Kampanie Google Ads search
										</Link>
									</li>
									<li>
										<Link to="/kampanie-wideo-na-youtube">
											Kampanie YouTube Ads
										</Link>
									</li>
									<li>
										<Link to="/kampanie-w-sieci-reklamowej-gdn">
											Kampanie w sieci reklamowej (GDN)
										</Link>
									</li>
									<li>
										<Link to="/kampanie-produktowe-product-listing-ads">
											Kampanie PLA
										</Link>
									</li>
									<li>
										<Link to="/tiktok-ads/">
											Kampanie TikTok Ads
										</Link>
									</li>
									<li>
										<Link to="/kampanie-facebook-ads">
											Kampanie Facebook Ads
										</Link>
									</li>
									<li>
										<Link to="/kampanie-na-instagramie">
											Kampanie na Instagramie
										</Link>
									</li>
									<li>
										<Link to="/kampanie-linkedin-ads">
											Kampanie na LinkedIn
										</Link>
									</li>
									{/* <li>
                  <Link to="">Kampanie Tiktok Ads</Link>
                </li> */}
									<li>
										<Link to="/kampanie-zagraniczne">
											Kampanie Zagraniczne
										</Link>
									</li>
									<li>
										<Link to="/banery-statyczne-i-dynamiczne">
											Banery statyczne i dynamiczne
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="home-offers__content__col">
							<div className="home-offers__content__row home-offers__content__row--blue">
								<h3>Social Media</h3>
								<ul>
									<li>
										<Link to="/audyt-kanalow-social-media">
											Audyt social media
										</Link>
									</li>
									<li>
										<Link to="/strategia-komunikacji-social-media">
											Strategia komunikacji
										</Link>
									</li>
									{/* <li>
                  <Link to="">social media</Link>
                </li> */}
									<li>
										<Link to="/prowadzenie-strony-na-facebooku">
											Prowadzenie strony na Facebooku
										</Link>
									</li>
									<li>
										<Link to="/prowadzenie-konta-instagram">
											Prowadzenie konta Instagram
										</Link>
									</li>
									<li>
										<Link to="/prowadzenie-strony-na-linkedin">
											Prowadzenie strony na LinkedIn
										</Link>
									</li>
									<li>
										<Link to="/kampanie-facebook-ads/">
											Kampanie Facebook Ads
										</Link>
									</li>
									<li>
										<Link to="/kampanie-na-instagramie/">
											Kampanie na Instagramie
										</Link>
									</li>
									<li>
										<Link to="/tiktok-ads/">
											Kampanie TikTok Ads
										</Link>
									</li>
									{/* <li>
										<Link to="/chatbot-w-komunikacji-marki">
											Automatyzacja komunikacji Chatbot
										</Link>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
					<div className="home-case-studies--inner-action">
						<Link to="/uslugi/" className="btn btn-line">
							zobacz wszystkie nasze usługi
						</Link>
					</div>
				</div>
			</section>

			<section
				className="home-google-agency"
				style={{ backgroundPositionX: scroll }}
			>
				<div className="container-fluid">
					<h2>
						Jesteśmy wśród <span>3%</span> najlepszych
						<br />
						agencji Google Ads w Polsce!
					</h2>
					<p>
						Najwyższe odznaczenie agencyjne Google Premier Partner 
						to ogromne wyróżnienie, które potwierdza wysoką jakość
						naszych usług i zaawansowane umiejętności i wiedzę w
						tworzeniu i optymalizacji kampanii Google Ads. Dzięki
						statusowi Google Partner Premier nasza agencja ma dostęp
						do szeregu narzędzi i funkcji, które pomogą nam jeszcze
						lepiej zoptymalizować Twoją kampanię.
					</p>
					<img
						src={require("../assets/images/google-premier-partner.svg")}
						alt=""
					/>
				</div>
			</section>

			<section className="home-case-studies">
				<div className="container-fluid">
					<div className="page-header">
						<span>#whenwewin</span>
						<h2>Case studies</h2>
					</div>
					<div className="row row-main">
						<div className="col-md-6 col-content">
							<div className="home-case-studies--inner">
								<h5>E-commerce - branża fashion</h5>
								<h3>
									Jak zwiększyliśmy przychody e-commerce z
									branży fashion z{" "}
									<strong>
										3 do 7 milionów złotych miesięcznie?
									</strong>
								</h3>
								<p>
									Poznaj tajniki naszej optymalizacji kampanii
									Google Ads i Meta Ads na budżetach{" "}
									<strong>
										ponad 590 000 złotych miesięcznie!
									</strong>
								</p>
								<div className="home-case-studies--inner-action">
									<Link
										to="/case-study/ecommerce-branza-fashion"
										className="btn btn-line"
									>
										więcej
									</Link>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-image col-image--right">
							<h1>
								<img
									className="img-fluid img-back"
									src={require("../assets/images/case-studies-top-back-v2.png")}
									alt="Marketing internetowy"
								/>
							</h1>
							<img
								className="img-fluid img-front"
								src={require("../assets/images/case-studies-top-front-v2.png")}
								alt=""
							/>
						</div>
					</div>

					<div className="row row-main">
						<div className="col-md-6 col-image col-image--left">
							<img
								className="img-fluid img-back"
								src={require("../assets/images/case-studies-bottom-back.png")}
								alt=""
							/>
							<img
								className="img-fluid img-front"
								src={require("../assets/images/case-studies-bottom-front.png")}
								alt=""
							/>
						</div>
						<div className="col-md-6 col-content">
							<div className="home-case-studies--inner">
								<h5>Branża nieruchomości</h5>
								<h3>
									<span>90 umów</span> rezerwacyjnych w{" "}
									<span>90</span> dni. Kampanie Google i
									Facebook Ads krakowskiej inwestycji
									mieszkaniowej Imperial Citi Yes
								</h3>
								<div className="row home-case-studies--inner-numbers">
									<div className="col-sm-6 number-col">
										<h4>16 731</h4>
										<p>
											użytkowników <br /> pobrało PDF
											mieszkań
										</p>
									</div>
									<div className="col-sm-6 number-col">
										<h4>155</h4>
										<p>
											pozyskanych <br /> formularzy
											kontaktowych
										</p>
									</div>
								</div>
								<div className="home-case-studies--inner-action home-case-studies--inner-action--right">
									<Link
										to="/case-study/imperial-citiyes/"
										className="btn btn-line"
									>
										więcej
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="text-center">
						<Link
							to="/case-study/"
							className="btn btn-box case-study-btn"
						>
							więcej <strong>case studies</strong>
						</Link>
					</div>
				</div>
			</section>

			<section className="home-clients">
				<div className="container-fluid">
					<div className="page-header">
						<span>Współpracują z nami</span>
						<h2>Klienci</h2>
					</div>
				</div>
				{/* <HomeClients onClientAnimation={onClientAnimation} /> */}
				<HomeClientsDynamic />
			</section>

			<section className="home-partnership">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-6 col-first">
							<img
								className="img-fluid"
								src={require("../assets/images/home-partnership-hand.png")}
								alt=""
							/>
							<h2>
								Win<span>-Win</span> <br /> Digital Agency
							</h2>
						</div>
						<div className="col-lg-5 col-md-6 col-second">
							<div>
								<h3>Partnerstwo w sukcesie</h3>
								<p>
									Stały rozwój naszych klientów pozwala
									rozwijać naszą agencję. Wyniki są ważne, ale
									to nie wszystko. Wierzmy i realizujemy
									przyjazną, partnerską współpracę opartą na
									zaufaniu i wzajemnych korzyściach. Twoje
									cele, to nasze cele. Twój sukces, to nasz
									sukces.{" "}
									<strong>
										Sprawdź, czym kierujemy się każdego dnia
									</strong>
								</p>
								<Link
									to="/win-win/"
									className="btn btn-line btn-line--light"
								>
									filozofia <strong>win-win</strong>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="home-contact">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-custom col-custom--brief">
							<img
								className="img-fluid"
								src={require("../assets/images/home-contact-hand-left.png")}
								alt=""
							/>
							<div
								className="text-wrapper"
								onMouseOver={zoomBrief}
								onFocus={zoomBrief}
								onMouseLeave={unzoomBrief}
							>
								<h3>
									Otrzymaj bezpłatną konsultację! <br />{" "}
									Wypełnij <br />
									<Link
										to="/brief"
										onMouseOver={zoomBrief}
										onFocus={zoomBrief}
										className="brief-box"
									>
										Brief
									</Link>
								</h3>
							</div>
						</div>
						<div className="col-custom col-custom--form">
							<div
								className="exit-form-button"
								onClick={closeFormByButton}
								onKeyDown={closeFormByButton}
							>
								<p>Wróć</p>
							</div>
							<img
								className="img-fluid"
								src={require("../assets/images/home-contact-hand-right.png")}
								alt=""
							/>
							<div
								className="text-wrapper"
								onMouseOver={zoomContact}
								onFocus={zoomContact}
								onMouseLeave={unzoomContact}
							>
								<h3
									onClick={contactFormOpen}
									onKeyDown={contactFormOpen}
								>
									<span className="mobile-or">
										lub <br />
									</span>
									Skontaktuj się z nami! <br /> Wypełnij{" "}
									<span>Formularz</span>
								</h3>
								<div className="form-wrapper">
									<ContactFormDark
										locales={locales[langKey]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
